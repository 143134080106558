import './App.css'
import rotterdam1 from './image/Rotterdam1.png'
import * as React from 'react'

export const App = () => {
  return (
    <div className="container" style={{backgroundImage: `url(${rotterdam1})`}}>
    <h1 className='header'>
      WELKOM OP DE SITE VAN S.D.M VAN WELL 
    </h1>
    <div className='text'>
    <h2>
      Email: Well@oranjegroep.nl / S.D.M.vanwell@gmail.com
    </h2>
    <h2>
      Telefoon: +31634250856 / +31104123399
    </h2>


    </div>
   
    </div>
  );
}
